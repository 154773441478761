// node_modules dependencies
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import Dropzone from 'vue2-dropzone'
import Trumbowyg from 'vue-trumbowyg'
import Quill from 'quill'
import { quillEditor } from 'vue-quill-editor'
import ImageResize from 'quill-image-resize-module'
import forEach from 'lodash/forEach'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import uniq from 'lodash/uniq'
import pullAt from 'lodash/pullAt'
import remove from 'lodash/remove'
import random from 'lodash/random'
import split from 'lodash/split'
import replace from 'lodash/replace'
import findIndex from 'lodash/findIndex'
import trimStart from 'lodash/trimStart'
import join from 'lodash/join'
import kebabCase from 'lodash/kebabCase'
import lowerCase from 'lodash/lowerCase'
import find from 'lodash/find'
import uniqBy from 'lodash/uniqBy'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'
import toLower from 'lodash/toLower'
import '../../../../node_modules/trumbowyg/dist/plugins/table/trumbowyg.table'
import debounce from 'lodash/debounce'
// Base Page
import BasePage from '@/pages/Base'

// Services
import ProductService from '@/services/Products'
import SupplierService from '@/services/Suppliers'
import WarehouseService from '@/services/Warehouses/warehouses'
import CountryService from '@/services/Country'
import ProvinceSevice from '@/services/Province'
import CitiesService from '@/services/City'
import BrandsService from '@/services/Brands'
import CurrencyService from '@/services/Currency'

// Configs
import config from '@/configs'
import { isUndefined } from 'util'

// Global Constant Variables
const storage = window.localStorage
const baseUrl = config.api.baseURL

// Quill init
Quill.register('modules/imageResize', ImageResize)

@Component({
  components: { Dropzone, quillEditor, Trumbowyg }
})
export default class EditProduct extends BasePage {
  constructor() {
    super()
  }
  // linknya image
  openModalUrl: boolean = false
  urlImage: string = null
  urlImageTemp: any = []

  // Token
  token: string = storage.getItem('access_token');

  // Title
  title: string = '';
  maxTitleCharacter: number = 500;
  titleHint: string = 'This is the title for your product';

  //Modal Edit Origin Language
  editOrigin: any = {
    open: false,
    product: {
      language: '',
      title: '',
      description: '',
      original_url: '', // url from chinabuybuy
      original_source_url: '' // url from 1688.com/taobao
    }
  };
  showModal_editOrigin() {
    this.editOrigin.open = true
  }
  save_editOrigin() {
    this.editOrigin.open = false
  }

  // Description
  content: string = '';
  editorOptions: object = {
    modules: {
      imageResize: {},
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        // [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ font: [] }, { color: [] }, { background: [] }],
        [{ direction: 'rtl' }],
        [{ align: [] }],
        ['link', 'image', 'video'],
        ['clean']
      ]
    },
    placeholder: 'Add product description here...',
    theme: 'snow'
  };
  config: object = {
    advanced: {
      autogrow: true,
      removeformatPasted: true,
      // btnsDef: {
      //   save: {
      //     fn: () => {
      //       this.onUpdateDescription();
      //     },
      //     text: "Save Changes",
      //     hasIcon: false,
      //     class: "custom-save-btn"
      //   }
      // },
      // btnsAdd: ["save"],
      btns: [
        ['viewHTML'],
        ['undo', 'redo'],
        ['formatting'],
        ['strong', 'em', 'del'],
        // ["link"],
        // ["insertImage"],
        ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
        ['unorderedList', 'orderedList'],
        ['horizontalRule'],
        ['removeformat'],
        ['fullscreen'],
        ['tableAddRow'],
        ['tableAddColumn'],
        ['tableDeleteRow'],
        ['tableDeleteColumn'],
        ['table'],
        ['save']
      ]
    }
  };

  // Currency
  currency: object[] = [
    {
      currencyName: 'IDR',
      currencySymbol: 'Rp'
    },
    {
      currencyName: 'USD',
      currencySymbol: '$'
    },
    {
      currencyName: 'CNY',
      currencySymbol: '¥'
    },
    {
      currencyName: 'THB',
      currencySymbol: 'THB'
    }
  ];
  // currencySelected: string = "";
  currencyHint: string = 'Select your currency';

  // Price
  regularPrice: string = '';
  regularPriceHint: string = 'Your regular price';
  maxRegularPriceValue: number = 1000000000000; // 1 Triliun
  salePrice: string = '';
  salePriceHint: string = 'Your sale price';
  maxSalePriceValue: number = 1000000000000; // 1 Triliun
  resellerPrice: string = ''
  resellerPriceHint: string = 'Reseller Price'
  maxResellerPriceValue: number = 1000000000000

  // Quantity
  quantity: string = '';
  quantityHint: string = 'Your product quantity';
  maxQuantityValue: number = 1000000000; // 1 Billion
  quantityUnit: any = [
    {
      text: 'Pcs',
      value: 'pcs'
    },
    {
      text: 'Sheets',
      value: 'sheets'
    },
    {
      text: 'Box',
      value: 'box'
    },
    {
      text: 'Crt',
      value: 'crt'
    },
    {
      text: 'Lsn',
      value: 'lsn'
    },
    {
      text: 'Pak',
      value: 'pak'
    },
    {
      text: 'Pack',
      value: 'pack'
    },
    {
      text: 'Set',
      value: 'set'
    },
    {
      text: 'Kg',
      value: 'kg'
    },
    {
      text: 'Ton',
      value: 'ton'
    },
    {
      text: 'Rol',
      value: 'rol'
    },
    {
      text: 'Bag',
      value: 'bag'
    },
    {
      text: 'Mtr',
      value: 'mtr'
    },
    {
      text: 'Dzn',
      value: 'dzn'
    },
    {
      text: 'Ltr',
      value: 'ltr'
    }
  ];
  quantityUnitSelected: string = '';
  quantityUnitHint: string = 'Select quantity unit';
  maxQuantityUnitCharacter: number = 10;

  // Weight
  weight: string = '';
  weightHint: string = 'Your product weight';
  maxWeightValue: number = 1000000; // 1 Million
  weightUnit: object[] = [
    {
      text: 'Kgs',
      value: 'kg'
    },
    {
      text: 'Gram',
      value: 'gr'
    },
    {
      text: 'Pounds',
      value: 'lbs'
    },
    {
      text: 'Ounce',
      value: 'oz'
    }
  ];
  weightUnitSelected: string = '';
  weightUnitHint: string = 'Select weight unit';

  // Dimension
  length: string = '';
  lengthHint: string = 'Your product length';
  maxLengthValue: number = 1000000; // 1 Million
  width: string = '';
  widthHint: string = 'Your product width';
  maxWidthValue: number = 1000000; // 1 Million
  height: string = '';
  heightHint: string = 'Your product height';
  maxHeightValue: number = 1000000; // 1 Million
  dimensionUnit: object[] = [
    {
      text: 'Meter',
      value: 'm'
    },
    {
      text: 'Centimeter',
      value: 'cm'
    },
    {
      text: 'Feet',
      value: 'ft'
    },
    {
      text: 'Inch',
      value: 'inch'
    }
  ];
  dimensionUnitSelected: string = '';
  dimensionUnitHint: string = 'Select dimension unit';

  // Domestic Shipping
  domesticShipping: number = null;

  // Quantity PerCBM
  quantityPerCBM: number = null;

  // SKU Product
  product_originalSku: string = null;
  product_aliSku: string = null;

  // Variants
  variants: any = [];
  newVariants: any = [];
  includeVariation: any = {};
  generatedVariants: any = [];
  generatedVariantPanelContent = null;
  availableSkus: any = [];
  maxSkuValue: number = 1000000000000; // 1 Triliun

  // Variant Items Image
  editVariantItemsImageDialog: boolean = false;
  dataVariantItemsImage: any = {
    items: []
  };
  fileImage: any = [];

  // Images
  options: any = {
    url: 'https://httpbin.org/post', // dummy post data. this will change later after adding an image
    headers: {
      Authorization: 'Bearer ' + this.token,
      Identifier: 'manage'
    },
    addRemoveLinks: true,
    dictDefaultMessage: 'Click Here or Drop Image Here To Upload',
    autoProcessQueue: false,
    acceptedFileTypes: 'image/*',
    maxNumberOfFiles: 10,
    maxFileSizeInMB: 2,
    duplicateCheck: true,
    thumbnailHeight: 250,
    thumbnailWidth: 250,
    paramName: 'images',
    parallelUploads: 10,
    uploadMultiple: true
  };

  // Selected as main images
  selectedMainImages: any = {};
  haveMainImage: boolean = false;

  // images fetch from API that gets deleted stored here. just the image id
  deletedImages: any = [];

  topCategory: number = null;
  categoryLevel2: number = null;
  categoryLevel3: number = null;
  opts: any = {
    params: {
      'filter[level][is]': '1',
      include: 'child',
      'page[limit]' : '100'
    }
  };
  topCategories: any = [];
  categoriesLevel2: any = [];
  categoriesLevel3: any = [];

  loadingCategories: boolean = false;
  loadingLevel2Categories: boolean = false;
  loadingLevel3Categories: boolean = false;

  noLevel2Categories: boolean = false;
  noLevel3Categories: boolean = false;

  // placeholder image
  imagePlaceholder: any = require('../../../assets/img/placeholder-image.jpg');

  viaPlaceholder: any = 'https://via.placeholder.com/150';

  previewImageSku: any = [];

  @Watch('topCategory', { deep: true })
  onChangedTopCategory(to: string, from: string) {
    this.getNextCategories(to, true)
  }
  @Watch('categoryLevel2', { deep: true })
  onChangedChildCategory(to: string, from: string) {
    this.getNextCategories(to)
  }

  async getCategories() {
    try {
      this.loadingCategories = true
      const responseCategories = await ProductService.getTopLevelCategories(
        this.opts
      )
      forEach(responseCategories.data.data, category => {
        let categoryMap = {
          categoryName: category.attributes.name,
          categoryId: category.attributes.id
        }

        this.topCategories.push(categoryMap)
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.loadingCategories = false
    }
  }

  async getNextCategories(categoryId, topCategory = false) {
    try {
      //set parentId
      let parentId = categoryId

      if (topCategory) {
        this.loadingLevel2Categories = true
        this.noLevel2Categories = false
        this.noLevel3Categories = false
        this.categoriesLevel2 = []
        this.categoriesLevel3 = []
      } else {
        this.loadingLevel3Categories = true
        this.noLevel3Categories = false
        this.categoriesLevel3 = []
      }

      const opts: any = {
        params: {
          'page[limit]' : '100'
        }
      }
      const responseData = await ProductService.getLowLevelCategories(parentId, opts)

      if (responseData.data.data.length > 0) {
        //assign response data to global object
        forEach(responseData.data.data, category => {
          let categoryMap = {
            categoryName: category.attributes.name,
            categoryId: category.attributes.id
          }

          if (topCategory) {
            this.categoriesLevel2.push(categoryMap)
            this.loadingLevel2Categories = false
          } else {
            this.categoriesLevel3.push(categoryMap)
            this.loadingLevel3Categories = false
          }
        })
      } else {
        //No category dimmers if no data
        if (topCategory) {
          this.loadingLevel2Categories = false
          this.noLevel2Categories = true
        } else {
          this.loadingLevel3Categories = false
          this.noLevel3Categories = true
        }
      }
    } catch (error) {
      this.catchHandler(error)
    }
  }

  // Label
  labelNames: any = [
    {
      text: 'Retail',
      value: 2
    },
    {
      text: 'Wholesale',
      value: 1
    },
    {
      text: 'Hot Product',
      value: 3
    },
    {
      text: 'Local',
      value: 4
    },
    {
      text: 'Imported Product',
      value: 5
    },
    {
      text: 'Fulfillment',
      value: 6
    },
    {
      text: 'COD',
      value: 9
    }
  ];
  labelVirtualName: any = [
    {
      text: 'This is Virtual Product',
      value: 8
    }
  ]
  selectedLabel: any = [];

  priority_number: number = null
  notShowinCatalog: boolean = false;

  // Product Recommendation
  idUser: any = [];
  getClientLoading: boolean = false;
  clientItem: any = [];
  keyword: string = null;
  searchDebounce: Function;
  searchWarehouseDebounce: Function;

  loadingTheEditProduct: boolean = false;

  async getClient() {
    this.getClientLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 25,
          sort: '-created_at',
          'filter[name][like]': ''
        }
      }

      this.loadingTheEditProduct = true
      if (!isEmpty(this.keyword)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.keyword
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }

      const response = await SupplierService.getClient(opts)

      const dataClient = response.data.data

      for (const clientList of dataClient) {
        const client: any = {
          name:
            clientList.attributes.name +
            ' (' +
            clientList.attributes.email +
            ')',
          id: clientList.attributes.id
        }
        this.clientItem.push(client)
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.loadingTheEditProduct = false
      this.getClientLoading = false
    }
  }

  // Currencies
  currencies: any = [];
  currencySelected: string = '';
  currencySelectedName: string = '';
  currencyKeyword: string = '';
  currencyNameHint: string = 'Select currency';
  getCurrenciesLoading: boolean = false;

  async getCurrencies() {
    try {
      this.getCurrenciesLoading = true
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 100,
          'filter[currency][like]': this.currencyKeyword
          // Calling all country. Reducing constant filter request to the server
        }
      }

      if (isEmpty(this.currencyKeyword)) {
        delete opts.params['filter[currency][like]']
      }

      const response = await CountryService.getCountries(opts)
      const responseData = response.data.data
      // console.log("getCurrencies() responseData", responseData)
      this.currencies = []
      for (const currency of responseData) {
        this.currencies.push(currency.attributes)
      }
      this.currencies = sortBy(this.currencies, 'currency')

    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getCurrenciesLoading = false
    }
  }

  // Country
  countries: any = [];
  countrySelected: string = '';
  countryNameHint: string = 'Select country';
  getCountriesLoading: boolean = false;

  async getCountries() {
    this.getCountriesLoading = true
    try {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 253 // Calling all country. Reducing constant filter request to the server
        }
      }

      const response = await CountryService.getCountries(opts)
      const responseData = response.data.data

      this.countries = []
      for (const country of responseData) {
        this.countries.push(country.attributes)
      }
      this.countries = sortBy(this.countries, 'name')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getCountriesLoading = false
    }
  }

  // Provinces
  provinces: any = [];
  provinceSelected: string = '';
  getProvinceLoading: boolean = false;
  provinceKeyword: string = null;

  async getProvinces(countryId) {
    this.getProvinceLoading = true
    try {
      const opts: any = {
        params: {
          'filter[country_id][is]': countryId,
          'filter[id][is]': this.provinceSelected
        }
      }

      if (!isEmpty(this.provinceKeyword)) {
        let textFilter = 'filter[id][is]'
        delete opts.params[textFilter]

        opts.params = {
          ...opts.params,
          'filter[name][like]': this.provinceKeyword
        }
      }

      await ProvinceSevice.getProvinces(opts).then(response => {
        for (const province of response.data.data)
          this.provinces.push(province.attributes)
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getProvinceLoading = false
    }
  }

  // Cities
  cities: any = [];
  citySelected: string = '';
  getCityLoading: boolean = false;
  cityKeyword: string = null;

  async getCities(provinceId) {
    this.getCityLoading = true
    try {
      const opts: any = {
        params: {
          'filter[province_id][is]': provinceId,
          'filter[id][is]': this.citySelected
        }
      }

      if (!isEmpty(this.cityKeyword)) {
        let textFilter = 'filter[id][is]'
        delete opts.params[textFilter]

        opts.params = {
          ...opts.params,
          'filter[name][like]': this.cityKeyword
        }
      }

      await CitiesService.getCities(opts).then(response => {
        for (const citie of response.data.data)
          this.cities.push(citie.attributes)
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getCityLoading = false
    }
  }

  // Supplier
  supplierNames: any = [];
  supplierSelected: string = '';
  supplierKeyword: string = '';
  getSupplierLoading: boolean = false;
  supplierHint: string = 'Select supplier';

  // brandNameOnly:
  brandNames: any = [];
  brandSelected: string = '';
  brandsKeyword: string = '';
  getBrandsLoading: boolean = false;
  brandHint: string = 'Select brand';

  async getSupplier() {
    try {
      this.getSupplierLoading = true
      const opts: any = {
        params: { include: 'city' }
      }

      if (!isEmpty(this.supplierKeyword)) {
        opts.params = {
          ...opts.params,
          'filter[name][like]': this.supplierKeyword
        }
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }

      const response = await SupplierService.getSuppliers(opts)
      // console.log("response", response)

      forEach(response.data, supplier => {
        const detail = {
          text: supplier.attributes.name,
          value: supplier.attributes.id
        }

        this.supplierNames.push(detail)
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getSupplierLoading = false
    }
  }

  async getBrands() {
    // console.log("getBrands()")
    try {
      this.getBrandsLoading = true
      const opts: any = {
        params: {
          // include: "city"
        }
      }

      if (!isEmpty(this.brandsKeyword)) {
        // if (this.brandsKeyword.length > 0) {
        opts.params = {
          ...opts.params,
          'filter[name][like]': this.brandsKeyword
        }
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
      }

      const response = await BrandsService.getBrandsLists(opts)
      // console.log("getBrands", response)

      forEach(response.data, brand => {
        const detail = {
          text: brand.attributes.name,
          value: brand.attributes.id
        }
        this.brandNames.push(detail)
      })

      // this.supplierNames.push(detail);


    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.getBrandsLoading = false
    }
  }

  // Table product rules
  productRulesTable: any = {
    tableHeaders: [
      {
        text: 'Rule Name',
        align: 'center',
        sortable: true,
        value: ''
      },
      {
        text: 'Rule Weight',
        align: 'center',
        sortable: true,
        value: ''
      },
      {
        text: 'Volumetric Weight',
        align: 'right',
        sortable: true,
        value: ''
      },
      {
        text: 'Implementations Methods',
        align: 'center',
        sortable: true,
        value: ''
      },
      {
        text: 'Quantity (PCS) / CBM',
        align: 'right',
        sortable: true,
        value: ''
      },
      {
        text: 'Dimensions ( W x H x L )',
        align: 'center',
        sortable: true,
        value: ''
      }
    ],
    tableItems: [],
    tableTotalItems: 0,
    tableRowsPerPageItems: [5, 10, 15, 25, 50, 100],
    tableLoading: false
  };

  // Minimum Quantity Order
  minimumQuantityOrder: number = null;

  //video url
  video_url: string = null

  // supplier note
  supplier_note: string = ''

  // country origin
  country_origin: string = null

  // Isexclusive
  is_Exclusive: boolean = false;

  // Wholesale Prices & Bulk Prices
  showWholesale_bulkDisk: boolean = false
  radioWholesale: any = ''
  showWholesaleandBulkPrice_Dialog: boolean = false;

  wholesalePrices: any = [];

  bulkPrices: any = [];
  bundlePrices: any = [];
  bandleUnit: object[] = [
    {
      text: 'CRT',
      value: 'crt'
    },
    {
      text: 'LSN',
      value: 'lsn'
    },
    {
      text: 'BOX',
      value: 'box'
    },
    {
      text: 'PAK',
      value: 'pak'
    },
    {
      text: 'SET',
      value: 'set'
    },
    {
      text: 'GRS',
      value: 'grs'
    }
  ];

  enableWholesalePrice: any = {
    openButtonAddNew: false,
    maxWholesaleBulkDiscount: 3,
    responseProduct: false
  };
  enableBulkPrice: any = {
    showButtonAddNew: false,
    maxBulkPrice: 3,
    responseProduct: false
  };

  itemsBulkQuantityUnit: any = [
    { text: 'Lusin', unit: 'lusin' },
    { text: 'Box', unit: 'box' },
    { text: 'Kodi', unit: 'kodi' },
    { text: 'Carton', unit: 'carton' },
    { text: 'Gross', unit: 'gross' },
    { text: 'Dozen', unit: 'dozen' },
    { text: 'Rim', unit: 'rim' },
    { text: 'Pack', unit: 'pack' }
  ];
  itemDeleteBulkPrice: any = [];

  // Edit Variant Name
  generatedVariantsTemp: any = [];

  url: string = null;

  warehouseItems: any = []
  warehouseSelected: string = '';
  warehouseDialogData: any = {
    loading: false,
    keyword: null
  }
  warehouseId: any = null
  warehouseHint: string = 'Select warehouse';

  get editor() {
    return (this.$refs as any).descriptionEditor.quill
  }
  get allGeneratedVariantsName() {
    return map(this.generatedVariants, 'variantName')
  }
  get currencyNameOnly() {
    return map(this.currency, 'currencyName')
  }
  get brandNameOnly() {
    return map(this.brandNames, 'brandName')
  }
  get quantityUnitOnly() {
    return find(
      this.quantityUnit,
      item => item.value == this.quantityUnitSelected
    )
  }

  mounted() {
    // Fetch Current Product Data based on it's ID passed in route params

    this.getClient()
    this.getSupplier()
    this.getBrands()
    this.getCountries()
    this.getCurrencies()
    this.getWarehouses()
    this.getProductData(this.$route.params.id)

    // Fetch initial categories
    this.getCategories();

    // Other Dropzone Events
    (this.$refs as any).images.dropzone.on('maxfilesexceeded', file => {
      (this.$refs as any).images.removeFile(file)
      this.showSnackbar({
        text: `Error! Image(s) too much. Max ${this.options.maxNumberOfFiles} Images`,
        color: 'red',
        timeout: 5000
      })
    })
  }

  @Watch('keyword')
  onChanged() {
    this.getClient()
  }
  @Watch('supplierKeyword')
  onSupplierChanged() {
    this.getSupplier()
  }
  @Watch('brandsKeyword')
  onBrandChanged() {
    this.getBrands()
  }
  @Watch('provinceKeyword')
  onProvincesChanged() {
    this.getProvinces(this.countrySelected)
  }
  @Watch('cityKeyword')
  onCitiesChanged() {
    this.getCities(this.provinceSelected)
  }

  @Watch('radioWholesale')
  onChangedWholesale() {
    if (this.radioWholesale == 'wholesalePrice') this.onEnableWholesalePrice()
    if (this.radioWholesale == 'bulkPrice') this.onEnableBulkPrice()
  }

  // Set attributes value when generatedVariants changed
  @Watch('generatedVariants')
  onVariantChanged() {
    this.setVariantsAttributes()
  }

  @Watch('currencySelected')
  onChangeCurrency() {
    const selectedCurrency = find(
      this.currencies,
      option => option.id === this.currencySelected
    )
    this.currencySelectedName = selectedCurrency ? selectedCurrency.currency : ''
  }

  @Watch('currencyKeyword')
  onChangecurrencyKeyword() {
    this.getCurrencies()
  }

  @Watch('warehouseDialogData.keyword')
  async onChangedWarehouse() {
    if (this.warehouseDialogData.keyword == '') {
      this.warehouseId = null
      this.searchWarehouseDebounce = debounce(this.getWarehouses, 500)
      this.searchWarehouseDebounce()
    } else if (this.warehouseId == null || (find(this.warehouseItems, item => item.id == this.warehouseId) && find(this.warehouseItems, item => item.id == this.warehouseId).name != this.warehouseDialogData.keyword)) {
      this.searchWarehouseDebounce = debounce(this.getWarehouses, 500)
      this.searchWarehouseDebounce()
    }
  }

  async getWarehouses() {
    this.warehouseItems = []
    this.warehouseDialogData.loading = true
    try {
      // if (this.supplierDialogData.countryOrigin === '') {
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 25,
          sort: '-created_at',
          'filter[name][like]': ''
        }
      }
      if (!isEmpty(this.warehouseDialogData.keyword)) {
        var textFilter = 'filter[name][like]'
        opts.params[textFilter] = this.warehouseDialogData.keyword
        // opts.params.searchJoin = 'and'
      } else {
        var textFilter = 'filter[name][like]'
        delete opts.params[textFilter]
        // delete opts.params.searchJoin
      }
      const response = await WarehouseService.getWarehouses(opts)

      const dataWarehouse = response.data

      for (const warehouseList of dataWarehouse) {
        const warehouse: any = {
          name: warehouseList.attributes.name,
          id: warehouseList.attributes.id
        }
        this.warehouseItems.push(warehouse)
      }
      // }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.warehouseDialogData.loading = false
    }
  }

  // BulkBulk/Wholesale Price
  onEnableWholesalePrice() {
    // this.salePrice = "";
    this.enableBulkPrice.showButtonAddNew = false

    if (this.enableWholesalePrice.responseProduct)
      this.enableWholesalePrice.responseProduct = false
    else if (this.wholesalePrices.length < 1)
      this.addWholesalePrice()
    this.enableWholesalePrice.openButtonAddNew = true
  }

  onEnableBulkPrice() {
    // this.salePrice = "";
    this.enableWholesalePrice.openButtonAddNew = false

    if (this.enableBulkPrice.responseProduct)
      this.enableBulkPrice.responseProduct = false
    else if (this.bulkPrices.length < 1)
      this.addBulkPrice()
    this.enableBulkPrice.showButtonAddNew = true
  }

  addWholesalePrice() {
    if (this.wholesalePrices.length < this.enableWholesalePrice.maxWholesaleBulkDiscount) {
      this.wholesalePrices.push({
        minimumQuantity: '',
        wholesalePrice: ''
      })
      if (this.wholesalePrices.length < this.enableWholesalePrice.maxWholesaleBulkDiscount) {
        this.enableWholesalePrice.openButtonAddNew = true
      } else this.enableWholesalePrice.openButtonAddNew = false
    }
  }

  addBulkPrice() {
    if (this.bulkPrices.length < this.enableBulkPrice.maxBulkPrice) {
      this.bulkPrices.push({
        type: 'bulk_price',
        action: 'create',
        dimension_unit: this.dimensionUnitSelected,
        weight_unit: this.weightUnitSelected,
        dimensions: [
          { label: 'length', value: null },
          { label: 'width', value: null },
          { label: 'height', value: null }
        ],
        weight: this.weight
      })
      if (this.bulkPrices.length < this.enableBulkPrice.maxBulkPrice) {
        this.enableBulkPrice.showButtonAddNew = true
      } else this.enableBulkPrice.showButtonAddNew = false
    }
  }

  removeWholesalePrice(wholesalePriceIndex) {
    this.wholesalePrices.splice(wholesalePriceIndex, 1)
    this.enableWholesalePrice.openButtonAddNew = true
  }

  removeBulkPrice(index) {
    let itemDelete = {
      type: this.bulkPrices[index].type,
      id: this.bulkPrices[index].id ? this.bulkPrices[index].id : null,
      action: 'delete'
    }
    this.itemDeleteBulkPrice.push(itemDelete)

    this.bulkPrices.splice(index, 1)
    this.enableBulkPrice.showButtonAddNew = true
  }

  // Fetch Current Product Data
  async getProductData(productId) {
    try {
      // Show Loading
      this.showLoading({ text: 'Getting Product Data...' })

      const opts = {
        params: {
          include:
            'categories,supplier,variations,images,owner,country,wholesale_prices,bulk_prices,options,warehouses'
        }
      }

      const response = await ProductService.getOneProduct(productId, opts)

      if (!isEmpty(response.included.warehouses) && !isEmpty(response.data.relationships.warehouses)) {
        this.warehouseId = response.included.warehouses[response.data.relationships.warehouses[0].id].attributes.id

        const tes = find(
          this.warehouseItems,
          option => option.id == response.included.warehouses[response.data.relationships.warehouses[0].id].attributes.id
        )
        if (!tes) {
          const warehouse: any = {
            name: response.included.warehouses[response.data.relationships.warehouses[0].id].attributes.name,
            id: response.included.warehouses[response.data.relationships.warehouses[0].id].attributes.id
          }

          this.warehouseItems.push(warehouse)
        }

      }



      // if() {

      // }

      this.brandSelected = response.data.attributes.brand_id

      const selectedCurrency = find(
        this.currencies,
        option => option.currency === response.data.attributes.original_currency
      )
      // this.currencySelected = response.data.attributes.original_currency;
      this.currencySelected = selectedCurrency ? selectedCurrency.id : null
      this.currencySelectedName = selectedCurrency ? selectedCurrency.currency : null

      this.title = response.data.attributes.title
      this.content = response.data.attributes.description
      this.regularPrice = response.data.attributes.regular_price
      this.salePrice = response.data.attributes.sale_price
      this.resellerPrice = response.data.attributes.reseller_price
      this.quantity = response.data.attributes.quantity
      this.quantityUnitSelected = toLower(
        response.data.attributes.quantity_unit
      )
      this.weight = response.data.attributes.weight
      this.weightUnitSelected = toLower(response.data.attributes.weight_unit)
      this.minimumQuantityOrder = response.data.attributes.minimum_qty
      this.video_url = response.data.attributes.video_url
      this.supplier_note = response.data.attributes.note
      this.country_origin = response.data.attributes.original_country
      this.editOrigin.product.language = 'Chinese'
      this.editOrigin.product.title = response.data.attributes.original_title
      this.editOrigin.product.description =
        response.data.attributes.original_description
      this.editOrigin.product.original_url = response.data.attributes.original_url
      this.editOrigin.product.original_source_url = response.data.attributes.original_source_url

      this.domesticShipping = response.data.attributes.domestic_shipping
        ? response.data.attributes.domestic_shipping
        : 0
      this.quantityPerCBM = response.data.attributes.qty_per_cbm
        ? response.data.attributes.qty_per_cbm
        : 0
      this.product_originalSku = response.data.attributes.sku
      this.product_aliSku = response.data.attributes.ali_sku

      if (!isEmpty(response.included.supplier)) {
        this.supplierNames.push({
          text:
            response.included.supplier[response.data.relationships.supplier.id]
              .attributes.name,
          value:
            response.included.supplier[response.data.relationships.supplier.id]
              .attributes.id
        })
        this.supplierSelected =
          response.included.supplier[
            response.data.relationships.supplier.id
          ].attributes.id
      }

      if (!isEmpty(response.included.warehouse)) {
        this.warehouseItems.push({
          text:
            response.included.warehouse[response.data.relationships.warehouse.id]
              .attributes.name,
          value:
            response.included.warehouse[response.data.relationships.warehouse.id]
              .attributes.id
        })
        this.warehouseSelected =
          response.included.warehouse[
            response.data.relationships.warehouse.id
          ].attributes.id
      }

      if (response.data.attributes.country_id != null) {
        this.countrySelected = response.data.attributes.country_id
      }
      if (response.data.attributes.province_id != null) {
        this.provinceSelected = response.data.attributes.province_id
        this.getProvinces(this.countrySelected)
      }
      if (response.data.attributes.city_id != null) {
        this.citySelected = response.data.attributes.city_id
        this.getCities(this.provinceSelected)
      }

      this.priority_number = response.data.attributes.priority

      // Tags
      const responseTags = response.included.tags
      console.log('responseTags',responseTags);

      forEach(responseTags, tag => {
        // if not default retail, check the rest of checkbox
        if (!isEmpty(tag)) {
          this.selectedLabel.push(tag.attributes.id)
        }
      })

      // Variants
      let variantsNames = []

      forEach(response.included.variations, variants => {
        forEach(variants.attributes.options_values, dataOptions => {
          variantsNames.push(dataOptions.name)
        })
      })

      this.newVariants = uniq(variantsNames)

      forEach(this.newVariants, variantName => {
        let variantItems = []
        let variantItems_origin = []
        let variantName_original = ''
        let variantImages = []
        forEach(response.included.variations, dataVariants => {
          forEach(dataVariants.attributes.options_values, dataOptions => {
            if (variantName === dataOptions.name) {
              variantItems.push(dataOptions.option)

              variantName_original = dataOptions.original_name
              let original_variants = {
                option: dataOptions.option,
                original_option: dataOptions.original_option
              }
              variantItems_origin.push(original_variants)
            }
          })
        })
        let variant = {
          variantName: variantName,
          variantName_original: variantName_original,
          variantNameHint: 'ex: Size, Color, Material',
          variantItems,
          variantItems_origin,
          variantItemsHint:
            'Add as many items as you need. Press enter to generate the variants',
          variantImages,
          variantId: '',
          variantItemsId: []
        }
        this.variants.push(variant)
      })

      // Data Options
      forEach(this.variants, (dataVariantItem, index) => {
        forEach(uniq(dataVariantItem.variantItems), varItem => {
          const dor = find(
            response.included.options,
            option => option.attributes.name === dataVariantItem.variantName
          )
          const dir = find(
            dor.attributes.option_value,
            opt_value => opt_value.attributes.value === varItem
          )
          if (
            dor.attributes.name === dataVariantItem.variantName &&
            dir.attributes.value === varItem
          ) {
            let variantImage = {
              id_option_value: dir.attributes.id,
              variantImage: dir.attributes.image_url
            }
            this.variants[index].variantImages.push(variantImage)
            this.variants[index].variantId = dor.attributes.id
            this.variants[index].variantItemsId.push({
              id: dir.attributes.id,
              value: dir.attributes.value
            })
          }
        })
      })

      // Generated Variants or SKUS
      forEach(this.variants, (variant, index) => {
        this.generateVariantItems(index)
      })

      this.includeVariation = response.included.variations

      this.setVariantsAttributes()

      // Images
      var img = ''
      var allImg = ''
      forEach(response.data.relationships.images, (image, index) => {
        img =
          '<img src=\'' +
          response.included.images[image.id].attributes.source +
          '\' style=\'max-width:100% !important;vertical-align:middle;margin-bottom:10px;\' />'
        allImg += img
        let mockFile: any = {
          name: `${image.id}_${response.included.images[image.id].attributes.source
            .split('/')
            .pop()
            .split('#')[0]
            .split('?')[0]
          }`,
          id: response.included.images[image.id].attributes.id,
          accepted: true,
          kind: 'image',
          status: 'fetched',
          type: 'image/jpeg',
          upload: {
            filename: response.included.images[image.id].attributes.source
              .split('/')
              .pop()
              .split('#')[0]
              .split('?')[0]
          },
          default: response.included.images[image.id].attributes.is_default
            ? true
            : false
        };
        (this.$refs as any).images.manuallyAddFile(
          mockFile,
          image.file,
          () => {
            console.log('Callback')
          },
          null,
          {
            dontSubstractMaxFiles: true,
            addToFiles: true
          }
        );
        (this.$refs as any).images.dropzone.emit(
          'thumbnail',
          mockFile,
          response.included.images[image.id].attributes.source
        )
      })

      // Products Rules
      this.productRulesTable.tableLoading = true

      const productRules = response.data.attributes.rules.product_rule

      forEach(productRules, productRule => {
        this.productRulesTable.tableItems = []
        const dimensionWidth = find(
          productRule.attributes.dimensions,
          dimension => lowerCase(dimension.label) === 'width'
        )
        const dimensionLength = find(
          productRule.attributes.dimensions,
          dimension => lowerCase(dimension.label) === 'length'
        )
        const dimensionHeight = find(
          productRule.attributes.dimensions,
          dimension => lowerCase(dimension.label) === 'height'
        )

        const pushProduct = {
          ...productRule.attributes,
          dimensionWidth: dimensionWidth.value,
          dimensionLength: dimensionLength.value,
          dimensionHeight: dimensionHeight.value
        }

        pushProduct.methodDisplayText =
          productRule.attributes.implemented_weight === 'real'
            ? 'Using Real Weight'
            : 'Using Volumetric Weight'

        this.productRulesTable.tableItems.push(pushProduct)
      })

      // Not Show in Catalog
      this.notShowinCatalog = response.data.attributes.not_show_in_catalog

      // Owner
      const responseOwners = response.included.owner

      if (!isEmpty(responseOwners)) {
        const idResponse = map(responseOwners, 'id')
        const idQuery = join(idResponse, ',')
        const opts: any = {
          params: {
            'filter[id][in]': idQuery
          }
        }

        const responseClientProduct = await SupplierService.getClient(opts)
        const dataClientProduct = responseClientProduct.data.data

        for (const clientListProduct of dataClientProduct) {
          const clientProduct: any = {
            name:
              clientListProduct.attributes.name +
              ' (' +
              clientListProduct.attributes.email +
              ')',
            id: clientListProduct.attributes.id
          }
          this.clientItem.push(clientProduct)
        }
      }

      forEach(responseOwners, owner => {
        // if not default retail, check the rest of checkbox
        if (!isEmpty(owner)) {
          if (owner.attributes.id !== 2) {
            this.idUser.push(owner.attributes.id)
          }
        }
      })

      // Category
      const responseCategories = response.included.categories

      forEach(responseCategories, category => {
        if (category.attributes.level === 1) {
          this.topCategory = category.attributes.id
        } else if (category.attributes.level === 2) {
          this.categoryLevel2 = category.attributes.id
        } else if (category.attributes.level === 3) {
          this.categoryLevel3 = category.attributes.id
        }
      })

      // Dimension
      if (!isEmpty(response.data.attributes.dimensions)) {
        const dimensionWidth = find(
          response.data.attributes.dimensions,
          dimension => lowerCase(dimension.label) === 'width'
        )
        const dimensionLength = find(
          response.data.attributes.dimensions,
          dimension => lowerCase(dimension.label) === 'length'
        )
        const dimensionHeight = find(
          response.data.attributes.dimensions,
          dimension => lowerCase(dimension.label) === 'height'
        )

        this.width = dimensionWidth.value
        this.length = dimensionLength.value
        this.height = dimensionHeight.value
      }

      if (!isEmpty(response.data.attributes.dimension_unit)) {
        this.dimensionUnitSelected = toLower(
          response.data.attributes.dimension_unit
        )
      }

      // Wholesale Prices
      this.showWholesale_bulkDisk = response.data.attributes.enable_pricing
      this.radioWholesale = response.data.attributes.pricing_type
      const responseWholesalePrices = response.included.wholesale_prices
      const responseBulkPrice = response.included.bulk_prices

      if (!isEmpty(responseWholesalePrices)) {
        this.enableWholesalePrice.responseProduct = true
        forEach(responseWholesalePrices, wholesales => {
          let wholesale = {
            id_wholesale_price: wholesales.id,
            minimumQuantity: wholesales.attributes.min_quantity,
            wholesalePrice: wholesales.attributes.price
          }
          this.wholesalePrices.push(wholesale)
        })

        if (this.wholesalePrices.length < this.enableWholesalePrice.maxWholesaleBulkDiscount)
          this.enableWholesalePrice.openButtonAddNew = true
        else this.enableWholesalePrice.openButtonAddNew = false
      }

      if (!isEmpty(responseBulkPrice)) {
        this.enableBulkPrice.responseProduct = true
        forEach(responseBulkPrice, bulkPrices => {
          let bulkPrice = {
            ...bulkPrices.attributes,
            type: bulkPrices.type,
            action: 'update'
          }
          if (bulkPrice.dimensions == null) {
            bulkPrice.dimensions = [
              { label: 'length', value: null },
              { label: 'width', value: null },
              { label: 'height', value: null }
            ]
          }

          this.bulkPrices.push(bulkPrice)
        })

        if (this.bulkPrices.length < this.enableBulkPrice.maxBulkPrice)
          this.enableBulkPrice.showButtonAddNew = true
        else this.enableBulkPrice.showButtonAddNew = false
      }

      //exclusive
      this.is_Exclusive = response.data.attributes.is_exclusive

      this.productRulesTable.tableTotalItems = productRules.length
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
      this.productRulesTable.tableLoading = false
    }
  }

  // Set variant attributes from API response
  setVariantsAttributes() {
    // Get each variant names combination per SKU
    forEach(this.generatedVariants, (value, index) => {
      let items = []
      forEach(
        split(this.generatedVariants[index].variantName, '<br>'),
        variantName => {
          let key = trimStart(split(variantName, ':')[0])
          let value = replace(
            replace(split(variantName, ':')[1], '&nbsp;<b>', ''),
            '</b>',
            ''
          )

          items.push({ key, value })
        }
      )

      // Set generated variant attributes based on included variation from API response
      forEach(items, generatedItems => {
        forEach(this.includeVariation, variants => {
          const thisVariantNames = []
          forEach(variants.attributes.options_values, options => {
            thisVariantNames.push({ key: options.name, value: options.option })
          })

          if (isEqual(sortBy(items), sortBy(thisVariantNames))) {
            this.generatedVariants[index].id = variants.id
            this.generatedVariants[index].original_sku =
              variants.attributes.sku
            this.generatedVariants[index].ali_sku = variants.attributes.ali_sku
            this.generatedVariants[index].quantity =
              variants.attributes.quantity
            this.generatedVariants[index].price =
              variants.attributes.regular_price
            this.generatedVariants[index].salePrice =
              variants.attributes.sale_price
            this.generatedVariants[index].resellerPrice =
              variants.attributes.reseller_price
            this.generatedVariants[index].imageUrl =
              variants.attributes.image_url == null
                ? this.viaPlaceholder
                : variants.attributes.image_url
          }
        })
      })
    })
  }

  // Variants
  addNewVariant() {
    this.variants.push({
      variantName: '',
      variantName_original: '',
      variantNameHint: 'ex: Size, Color, Material',
      variantNameError: [],
      variantItems: [],
      variantItems_origin: [],
      variantItemsHint:
        'Add as many items as you need. Press enter to generate the variants',
      variantItemsError: [],
      variantImages: [],
      variantId: '',
      variantItemsId: []
    })
  }
  variant_index: number = null;

  // Variant Items Image
  editVariantItemsImage(variantIndex) {
    this.variant_index = variantIndex
    this.editVariantItemsImageDialog = true
    this.dataVariantItemsImage.items = []
    forEach(this.variants[variantIndex].variantItems, (variantItem, index) => {
      if (this.variants[variantIndex].variantImages[index] != undefined) {
        const item = {
          name: this.variants[variantIndex].variantName,
          option: variantItem,
          imageUrl:
            this.variants[variantIndex].variantImages[index].variantImage !=
              undefined
              ? this.variants[variantIndex].variantImages[index].variantImage
              : this.viaPlaceholder,
          id_option_value:
            this.variants[variantIndex].variantImages[index].id_option_value !=
              undefined
              ? this.variants[variantIndex].variantImages[index].id_option_value
              : ''
        }
        this.dataVariantItemsImage.items.push(item)
        if (
          this.variants[variantIndex].variantImages[index].variantImage ===
          undefined
        ) {
          this.variants[variantIndex].variantImages[
            index
          ].variantImage = this.viaPlaceholder
        }
      } else {
        const item = {
          name: this.variants[variantIndex].variantName,
          option: variantItem,
          imageUrl: this.viaPlaceholder,
          id_option_value: ''
        }
        this.dataVariantItemsImage.items.push(item)

        const variantImages = {
          id_option_value: '',
          variantImage: this.viaPlaceholder
        }
        this.variants[variantIndex].variantImages.push(variantImages)
      }
    })
  }

  // when new chip inside variant items is added
  generateVariantItems(variantIndex) {
    this.generatedVariants = []
    forEach(this.addVariantItems(this.variants, variantIndex), variant => {
      this.generatedVariants.push({
        variantName: variant,
        id: '',
        quantity: 0,
        price: 0,
        salePrice: '',
        resellerPrice: '',
        original_sku: '0',
        ali_sku: 'ALI-',
        imageUrl: this.viaPlaceholder
      })
    })

    // Create Image Variant for New Variant Items
    forEach(
      uniq(this.variants[variantIndex].variantItems),
      (variant, index) => {
        if (this.variants[variantIndex].variantImages[index] === undefined) {
          const variantImages = {
            id_option_value: '',
            variantImage: this.viaPlaceholder
          }
          this.variants[variantIndex].variantImages.push(variantImages)
        }
      }
    )

    // create_origin_variant
    var create_new = []
    for (const variantItems of this.variants[variantIndex].variantItems) {
      const findVariantItems = this.variants[variantIndex].variantItems_origin.find(item => item.option === variantItems)
      if (findVariantItems !== undefined) {
        const data = {
          option: findVariantItems.option,
          original_option: findVariantItems.original_option
        }
        create_new.push(data)
      } else {
        const data = {
          option: variantItems,
          original_option: variantItems
        }
        create_new.push(data)
      }
    }
    this.variants[variantIndex].variantItems_origin = create_new
    // create duplicate-free array
    if (this.variants[variantIndex].variantItems !== undefined)
      this.variants[variantIndex].variantItems = uniq(
        this.variants[variantIndex].variantItems
      )
    if (this.variants[variantIndex].variantItems_origin !== undefined)
      this.variants[variantIndex].variantItems_origin = uniqBy(
        this.variants[variantIndex].variantItems_origin,
        'option'
      )
  }
  // Edit Variant Name
  generateVariantItemsEdit(variantIndex) {
    if (!isEmpty(this.variants[variantIndex])) {
      this.generatedVariantsTemp = this.generatedVariants
      this.generatedVariants = []
      let index = 0
      forEach(this.addVariantItems(this.variants, variantIndex), variant => {
        this.generatedVariants.push({
          variantName: variant,
          id: this.generatedVariantsTemp[index].id,
          quantity: this.generatedVariantsTemp[index].quantity,
          price: this.generatedVariantsTemp[index].price,
          salePrice: this.generatedVariantsTemp[index].salePrice,
          resellerPrice: this.generatedVariantsTemp[index].resellerPrice,
          original_sku: this.generatedVariantsTemp[index].original_sku,
          ali_sku: this.generatedVariantsTemp[index].ali_sku,
          imageUrl: this.generatedVariantsTemp[index].imageUrl
        })
        index++
      })
    }
  }
  addVariantItems(variants, variantIndex) {
    const add = (
      [{ variantName, variantItems }, ...variants]: any,
      row = []
    ) => {
      const uniqVariant = uniq(variantItems) // create duplicate-free array
      return [].concat(
        ...uniqVariant.map(variantItem => {
          const currRow = [
            ...row,
            `${variantName}:&nbsp;<b>${variantItem}</b>`
          ]
          return variants.length
            ? add(variants, currRow)
            : currRow.join('<br>') // instead of currRow.join(', ') replace with [currRow] if you want an array
        })
      )
    }
    return add(variants)
  }
  getAllVariantsNameExcept(variantIndex) {
    let allVariantName = map(this.variants, 'variantName') // get all variant name only
    let filter = pullAt(allVariantName, variantIndex) // drop current position
    return allVariantName // return all variant name without current index
  }
  removeVariant(variantIndex) {
    this.variants.splice(variantIndex, 1)
    isEmpty(this.variants)
      ? this.removeGeneratedVariants()
      : this.generateVariantItems(variantIndex)
    this.generateVariantItems(variantIndex)
  }
  removeGeneratedVariants() {
    remove(this.generatedVariants)
  }
  removeVariantItems(variantIndex, itemIndex) {
    this.variants[variantIndex].variantItems.splice(itemIndex, 1)
    isEmpty(this.variants[variantIndex].variantItems)
      ? this.removeVariant(variantIndex)
      : this.generateVariantItems(variantIndex)
    this.generateVariantItems(variantIndex)
  }

  // Images
  imageAdded(file) {
    if (file.id === undefined) {
      file.id = file.upload.uuid
    }
    if (file.default) {
      this.setMainImage(file)
    }
    file.previewElement.addEventListener('click', () => {
      this.setMainImage(file)
      if (!isEmpty(this.selectedMainImages)) {
        this.showSnackbar({
          text: `${file.name} is set as main image`,
          color: 'green',
          timeout: 2000
        })
      }
    })
  }
  imageAddedManually(file) {
    // console.log(file)
  }
  imageAddedError(file) {
    if (
      file.type != 'image/jpg' &&
      file.type != 'image/jpeg' &&
      file.type != 'image/png'
    ) {
      (this.$refs as any).images.removeFile(file)
      this.showSnackbar({
        text: 'Error! Not an image file(color: s)',
        color: 'red'
      })
    } else if (file.size > this.options.maxFileSizeInMB * 1024 * 1024) {
      (this.$refs as any).images.removeFile(file)
      this.showSnackbar({
        text: `Error! Image(s) size is too large. Max ${this.options.maxFileSizeInMB} MB`,
        color: 'red',
        timeout: 5000
      })
    }
    this.error()
  }
  imageDuplicate(file) {
    this.showSnackbar({
      text: 'Image is already added',
      color: 'orange',
      timeout: 2000
    })
  }
  imageRemoved(file: any, error: any, xhr: any) {
    if (file.status === 'fetched') this.deletedImages.push(file.id)
  }
  beforeSendingImage(file, xhr, formData) {
    formData.append('type', 'image')
  }
  beforeSendingImageMultiple(file, xhr, formData) {
    formData.append('type', 'image')
  }
  imageUploadSuccess(file, response) {
    if ((this.$refs as any).images.getUploadingFiles().length <= 0) this.done()
  }
  imageUploadMultipleSuccess(files, response) {
    if ((this.$refs as any).images.getUploadingFiles().length <= 0) {
      let selectedNewImage = false
      forEach(files, (uploadedImage, index) => {
        if (this.selectedMainImages.id === uploadedImage.id) {
          ProductService.setMainImage(this.$route.params.id, {
            image_id: response.data[index].id
          })
          selectedNewImage = true
        }
      })
      if (!selectedNewImage && !isEmpty(this.selectedMainImages)) {
        ProductService.setMainImage(this.$route.params.id, {
          image_id: this.selectedMainImages.id
        })
      }
      this.done()
    }
  }
  imageUploadProgress(totaluploadprogress, totalBytes, totalBytesSent) { }

  // Form Submited
  submit() {
    // console.log("this.salePrice",this.salePrice)
    // console.log("this.resellerPrice", this.resellerPrice)

    // Check validation
    this.$validator.validateAll().then(response => {
      if (response) {
        this.showLoading({ text: 'Saving Product...' })

        // Product Attributes
        let attributes = []
        let addOnAttributes = []
        // console.log("this.variants", this.variants)
        // console.log("this.variants", this.variants)
        // console.log("this.editOrigin", this.editOrigin)
        // variants

        forEach(this.variants, (valueName, index) => {
          forEach(this.variants[index].variantItems, (valueItem, i) => {
            const id = this.variants[index].variantItemsId.find(el => el.value === valueItem)
            // console.log("this.variants[index].variantItems",this.variants[index].variantItems_origin[i])
            addOnAttributes.push({
              key_id: valueName.variantId,
              key: valueName.variantName,
              value: this.variants[index].variantItems_origin[i].option
            })
            attributes.push({
              key_id: valueName.variantId,
              key: valueName.variantName,
              value: valueItem,
              // value: this.variants[index].variantItems_origin[i].option,

              value_id: id ? id.id : null,
              property: `${valueName.variantName}:${valueItem};${random(
                0,
                this.toUnixMillisecondTimestamp()
              )}`,
              image_url: this.variants[index].variantImages[i].variantImage,
              original_key: valueName.variantName_original,
              original_value: this.variants[index].variantItems_origin[i]
                .original_option
            })
          })
        })

        // for attributes make newAttributes send to payload
        // for replace
        let newAttributes = []
        forEach(attributes, (val, index) => {
          const itm = {
            ...val,
            value: addOnAttributes[index].value
          }
          newAttributes.push(itm)
        })
        // console.log("newAttributes",newAttributes)
        // console.log("addOnAttributes",addOnAttributes)
        // console.log("attributes",attributes)

        // Product SKUS
        let skus = []
        forEach(this.generatedVariants, (value, index) => {
          // Items
          let items = []
          forEach(
            split(this.generatedVariants[index].variantName, '<br>'),
            variantName => {
              let key = trimStart(split(variantName, ':')[0])
              let value = replace(
                replace(split(variantName, ':')[1], '&nbsp;<b>', ''),
                '</b>',
                ''
              )
              let uniqueId = findIndex(attributes, object => {
                return object.key === key && object.value === value
              })
              // console.log("uniqueId", uniqueId)
              let property = attributes[uniqueId].property
              // console.log("property", property)
              items.push({
                key,
                value,
                property,
                product_id: this.$route.params.id
              })
            }
          )
          console.log('items', items)
          // console.log("newAttributes["+index+"]",
          if (newAttributes[index]) {
            if (newAttributes[index].property === items[0].property) {
              items[0].value = newAttributes[index].value
            }
          }

          // Sku
          skus.push({
            items,
            variant_id: this.generatedVariants[index].id,
            sku_id: this.generatedVariants[index].original_sku,
            ali_sku: this.generatedVariants[index].ali_sku,
            price: this.generatedVariants[index].price,
            sale_price: this.generatedVariants[index].salePrice,
            reseller_price: this.generatedVariants[index].resellerPrice,
            price_strike: this.generatedVariants[index].salePrice,
            quantity: this.generatedVariants[index].quantity,
            properties: join(map(items, 'property'), '-'),
            image_url: this.generatedVariants[index].imageUrl
          })
        })

        // Wholesale/Bulk Discount
        let wholesale_prices = []

        forEach(this.wholesalePrices, (data, index) => {
          let countwholesalePrices = this.wholesalePrices.length - 1

          if (index == countwholesalePrices.toString()) {
            let attributes = {
              min_quantity: data.minimumQuantity,
              max_quantity: 0,
              price: data.wholesalePrice
            }

            wholesale_prices.push({ attributes })
          } else {
            let attributes = {
              min_quantity: data.minimumQuantity,
              max_quantity:
                this.wholesalePrices[index + 1].minimumQuantity - 1,
              price: data.wholesalePrice
            }

            wholesale_prices.push({ attributes })
          }
        })

        forEach(this.itemDeleteBulkPrice, data => {
          if (data.id != null) this.bulkPrices.push(data)
        })

        // Selected Category
        let selectedCategory = [
          this.topCategory,
          this.categoryLevel2,
          this.categoryLevel3
        ]

        let formattedSelectedCategory = selectedCategory.filter(
          categoryId => categoryId !== null
        )

        // setCurrencySelected
        const selectedCurrencyForPayload = find(
          this.currencies,
          option => option.id === this.currencySelected
        )

        // Final JSON data that sent to API
        let newProduct = {
          // attributes,
          attributes: newAttributes,
          skus,
          title: this.title,
          description: this.content,
          // old
          // currency: this.currencySelected,
          currency: selectedCurrencyForPayload.currency,
          price: this.regularPrice,
          sale_price: this.salePrice,
          reseller_price: this.resellerPrice,
          quantity: Number(this.quantity),
          unit: this.quantityUnitSelected,
          weight: this.weight,
          weight_unit: this.weightUnitSelected,
          keyword: kebabCase(this.title),
          stock_status: 'Recently Added',
          deleted_images: this.deletedImages, // will be processed later in API
          categories: formattedSelectedCategory,
          tags: this.selectedLabel,
          not_show_in_catalog: this.notShowinCatalog,
          priority: this.priority_number,
          supplier_id: this.supplierSelected,
          country_id: this.countrySelected,
          province_id: this.provinceSelected,
          city_id: this.citySelected,
          dimensions: [
            {
              label: 'width',
              value: this.width
            },
            {
              label: 'length',
              value: this.length
            },
            {
              label: 'height',
              value: this.height
            }
          ],
          dimension_unit: this.dimensionUnitSelected,
          user_ids: this.idUser,
          minimum_qty: this.minimumQuantityOrder,
          video_url: this.video_url,
          wholesale_prices,
          original_language: this.editOrigin.product.language,
          original_title: this.editOrigin.product.title,
          original_description: this.editOrigin.product.description,
          original_url: this.editOrigin.product.original_url,
          original_source_url: this.editOrigin.product.original_source_url,
          domestic_shipping: this.domesticShipping,
          qty_per_cbm: this.quantityPerCBM,
          sku: this.product_originalSku,
          ali_sku: this.product_aliSku,
          relationships: this.bulkPrices,
          enable_pricing: this.showWholesale_bulkDisk,
          pricing_type: this.radioWholesale,
          is_exclusive: this.is_Exclusive,
          brand_id: this.brandSelected,
          original_country: this.country_origin,
          note: this.supplier_note,
          warehouses: this.warehouseId
        }

        // console.log("newProduct", JSON.stringify(newProduct))
        // console.log("brandSelected", this.brandSelected)
        // console.log("newProduct", newProduct)

        // Send Product to API
        ProductService.updateProduct(this.$route.params.id, newProduct)
          .then(response => {
            // Send Images
            if ((this.$refs as any).images.getQueuedFiles().length > 0) {
              this.showLoading({ text: 'Uploading Images...' });
              (this.$refs as any).images.setOption(
                'url',
                `${baseUrl}api/v2/products/${this.$route.params.id}/updateimages`
              );
              (this.$refs as any).images.processQueue()
            } else {
              // set main image if no new images uploaded
              if (!isEmpty(this.selectedMainImages)) {
                ProductService.setMainImage(this.$route.params.id, {
                  image_id: this.selectedMainImages.id
                })
              }
              this.done()
            }
            this.submitUrlImage(response.data.attributes.id)
          })
          .catch(error => this.catchHandler(error))
      } else {
        this.showSnackbar({
          text: 'Please fill all the required filed(s)',
          color: 'red',
          timeout: 3500
        })
        this.generatedVariantPanelContent = 0
      }
    })
  }

  done() {
    this.showSnackbar({
      text: 'Product successfully saved',
      color: 'green',
      timeout: 2000
    })
    setTimeout(() => {
      this.closeLoading()
      // this.$router.push("/products");
      // location.reload();
    }, 2000)
  }

  error() {
    this.closeLoading()
    this.showSnackbar({
      text: 'Error! Something Happened. Check your internet connection',
      color: 'red',
      timeout: 4000
    })
  }

  setMainImage(file) {
    if (!isEmpty(file)) {
      if (isEmpty(this.selectedMainImages)) {
        this.selectedMainImages = file
        this.haveMainImage = true
        file.previewElement.classList.add('selected-main')
      } else if (file.id !== this.selectedMainImages.id) {
        this.selectedMainImages.previewElement.classList.remove(
          'selected-main'
        )
        this.selectedMainImages = file
        this.haveMainImage = true
        file.previewElement.classList.add('selected-main')
      } else if (file.id === this.selectedMainImages.id) {
        this.selectedMainImages.previewElement.classList.remove(
          'selected-main'
        )
        this.selectedMainImages = {}
        this.haveMainImage = false
      }
    } else {
      this.haveMainImage = false
    }
  }

  // upload sku images
  onSelectedSkuImages(e, index) {
    const file = e.target.files[0]

    if (!isUndefined(file)) {
      this.showLoading({ text: 'Uploading SKU Image...' })
      let data = new FormData()
      if (this.generatedVariants[index].id !== '') {
        data.append('id', this.generatedVariants[index].id)
      }
      data.append('image', file)

      let config = {
        header: {
          'Content-Type': 'image/*',
          Authorization:
            'Bearer ' + window.localStorage.getItem('access_token'),
          Identifier: 'manage'
        }
      }

      ProductService.setSkuImage(data, config).then(response => {
        this.generatedVariants[index].imageUrl = response.data.image_url
        this.previewImageSku[index] = response.data.image_url
        this.closeLoading()
        this.showSnackbar({
          text: 'SKU image has ben uploaded',
          color: 'green',
          timeout: 2000
        })
      })
    }
  }
  // end upload sku image

  imageFile: any = [];

  onSelectedVariantImages(event, index) {
    this.imageFile = event.target.files[0]
    this.dataVariantItemsImage.items[index].imageUrl = URL.createObjectURL(
      this.imageFile
    )
    this.dataVariantItemsImage.items[index].imageFile = this.imageFile
    let configimg

    configimg = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + window.localStorage.getItem('access_token'),
        Identifier: 'manage'
      }
    }

    let data = new FormData()

    data.append('image', this.dataVariantItemsImage.items[index].imageFile)

    if (this.dataVariantItemsImage.items[index].id_option_value !== '') {
      data.append(
        'id',
        this.dataVariantItemsImage.items[index].id_option_value
      )
    }

    ProductService.setOptionValueImage(data, configimg).then(response => {
      this.dataVariantItemsImage.items[index].imageUrl =
        response.data.image_url
      this.variants[this.variant_index].variantImages[index].variantImage =
        response.data.image_url
    })
  }

  //open Modal
  modalImageUrl() {
    this.urlImage = ''
    this.openModalUrl = true
  }

  uploadUrl() {
    // console.log(this.urlImageTemp, 'atas');
    this.openModalUrl = false
    // console.log(this.urlImage.split(';'));
    forEach(this.urlImage.split(';'), split => {
      this.urlImageTemp.push(split)
    })
    // console.log(this.urlImageTemp, 'bawah');
    var img = ''
    forEach(this.urlImage.split(';'), (image, index) => {
      img =
        '<img src=\'' +
        image +
        '\' style=\'max-width:100% !important;vertical-align:middle;margin-bottom:10px;\' />'
      let mockFile: any = {
        name: `${index}_${image
          .split('/')
          .pop()
          .split('#')[0]
          .split('?')[0]
        }`,
        id: index,
        accepted: true,
        kind: 'image',
        status: 'fetched',
        type: 'image/jpeg',
        upload: {
          filename: image
            .split('/')
            .pop()
            .split('#')[0]
            .split('?')[0]
        },
        default: image
          ? true
          : false
      };
      (this.$refs as any).images.manuallyAddFile(
        mockFile,
        image,
        () => {
          console.log('Callback')
        },
        null,
        {
          dontSubstractMaxFiles: true,
          addToFiles: true
        }
      );
      (this.$refs as any).images.dropzone.emit(
        'thumbnail',
        mockFile,
        image
      )
    })
  }

  cancelUrl() {
    this.openModalUrl = false
  }

  async submitUrlImage(id) {
    let data =
    {
      data: [
        {
          id: id,
          relationships:
            [
              {
                type: 'images_url',
                urls: this.urlImageTemp
              }
            ]
        }
      ]
    }
    await ProductService.updateTitleProduct(data)
  }
}
